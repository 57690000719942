.video_child{
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
    background-color: white;
    width: 90%;
    display: flex;
    justify-content: center;
    padding: 20px;
    margin: 50px 50px;
    border-radius: 10px;
    flex-wrap: wrap;
}
.video_main{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(231, 240, 249);
    height: auto;
    width: 100%;
}
.video_st_ch{
    width: 350px;
    height: 60vh;
    border-radius: 15px;
}
.video_child_child{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 60%;


}
.h2__tag{
    color: rgb(211, 177, 82);
    width: 100%;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}