.main_container_form {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
}

.carloan_form {
    background-color: white;
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    box-shadow: 2px 4px 10px 1px rgb(211, 177, 82);
    width: 95vw !important;
    height: auto;
    border-radius: 7px;

}

.input1_parent {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    margin-top: 20px;
    padding: 0px 20px 0px 20px;
}

.input1_child {
    width: 300px !important;
    height: 40px;
    padding-left: 10px;
    font-size: 15px;
    margin: 15px 0px;
    border: #cbd6f2;
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
    border-radius: 5px;
}
.input1_childst2 {
    width: 300px;
    height: 40px;
    padding-left: 10px;
    font-size: 15px;
    margin: 15px 0px;
    border: #cbd6f2;
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
    border-radius: 5px;
}

.sigCanvas {
    width: 300px;
    height: 200px;
    margin: 15px 0px;
    border: #cbd6f2;
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
    border-radius: 5px;
}

.Clear_signature {
    width: 100px;
    font-size: 15px;
    height: 40px;
    align-items: center;
    text-align: center;
    display: flex;
    justify-content: center;
    background-color: rgb(211, 177, 82);
    color: white;
}
.error_check_emty {
    color: red;
    font-size: 12px;
  }
.label_1 {
    font-size: 17px;
    color: rgb(211, 177, 82);


}

.head_loan {
    padding: 0px 20px 0px 20px;
    font-size: 18px;
}

.fill_p {
    padding: 18px 20px 18px 20px;
    font-weight: 500;
    color: black;
    font-size: 18px;
}

.checkbox_st {
    margin-top: 20px;
    padding: 10px 0px 0px 20px;
}

.radio_st {
    margin: 30px 0px 0px 0px;
}

.radio_stt {
    margin: 20px 0px 10px 0px;
}

.time_st {
    border-bottom: 1px solid;
    padding-left: 10px;
    margin: 0px 0px 0px 0px !important;
    width: 40px;
    font-size: 15px;

}

.time_stprev {
    border-bottom: 1px solid;
    padding-left: 10px;
    margin: 25px 0px 0px 0px;
    width: 40px;
    font-size: 15px;

}

.time_main_st {
    width: 300px !important;
    height: 40px;
    margin-bottom: 60px !important;

}


/* Signature Pad */
.containerst {
    width: 70vw;
    height: auto;
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
}

.sigContainer {
    width: 300px;
    height: auto;
    background-color: #fff;

}

.sigPadd {
    width: 300px;
    height: 200px;
    margin: 15px 0px;
    border: #cbd6f2;
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
    border-radius: 5px;

}

.buttonst {
    width: auto;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 10px;
    background-color: rgb(211, 177, 82);
    color: white;
}
.clearbtn{
    width: auto;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 10px;
    background-color: black;
    color: white;
}

.sigImage {
    background-size: 300px 50px;
    width: 20vw;
    height: 150px;
    background-color: white;
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
    margin-top: 20px;
    border-radius: 10px;
}
.submit_container{
    margin: 20px 20px;
    display: flex;
    justify-content: center;
}
.submit_child_st{
    width: 30vw;
    height: 45px;
    display: flex;
    font-size: 16px;
    justify-content: center;
    align-items: center;
    background-color: rgb(211, 177, 82);
    color: white;
    
}