.location .box {
    position: relative;
    border-radius: 5px;
}

.location img {
    border-radius: 5px;
}

.imgselection {
    height: 250px !important;
    width: 380px;
    transition: transform .2s;
    /* Animation */
}

.imgselection:hover {
    transform: scale(1);
}

.hovereff {
    transition: transform .4s;
    /* Animation */
    cursor: pointer;

}

.hovereff:hover {
    transform: scale(0.9);
}

.content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

}

.location .overlay {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 250px;
    width: 100%;
    color: #fff;
    z-index: 222;
}

.location .overlay::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 340px;
    height: 210px;
    background: rgb(15 28 47 / 30%);
    z-index: -1;
    margin: 20px;
    border-radius: 5px;
}

.location h5 {
    font-size: 18px;
    font-weight: 500;
}

.location label {
    color: #fff;
    margin-right: 20px;
    opacity: 0.8;
}

@media screen and (max-width: 800px) {
    .location .overlay::after {
        width: 280px;
        height: 170px;
    }
}
@media screen and (max-width:600px) {
    .display_car_animatio{
        display: none;
    }
    
}

/* Car animation */

.sky {
    height: 50vh;
    width: 100%;
    background-image: url('../images/sk.jpeg');
    background-size: cover;
    background-position: center;
    position: relative;
    overflow: hidden;
    border-radius: 15px;
}

.highway {
    height: 200px;
    width: 1000%;
    display: block;
    background-image: url('../images/rd.jpeg');
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background-repeat: repeat;
    animation: highway 5s linear infinite;
}

@keyframes highway {
    100% {
        transform: translateX(-3000px);
    }
}

.city {
    height: 250px;
    width: 500%;
    background-image: url('../images/cty.png');
    position: absolute;
    left: 0;
    right: 0;
    bottom: 200px;
    display: block;
    z-index: 1;
    background-repeat: repeat;
    animation: city 20s linear infinite;
}

@keyframes city {
    100% {
        transform: translateX(-1500px);
    }
}

.car {
    width: 400px;
    left: 50%;
    bottom: 100px;
    transform: translateX(-50%);
    position: absolute;
    z-index: 2;
}

.car img {
    width: 100%;
    animation: car 1s linear infinite;
}

@keyframes car {
    0% {
        transform: translateY(-4px);
    }

    50% {
        transform: translateY(0px);
    }

    100% {
        transform: translateY(-4px);
    }
}

.wheel {
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    bottom: 185px;
    z-index: 2;
}

.wheel img {
    width: 72px;
    height: 72px;
    animation: wheel 1s linear infinite;
    
}

@keyframes wheel {
    100% {
        transform: rotate(360deg);
    }
}

.frontwheel {
    position: absolute;
    left: 80px;
}

.backwheel {
    position: absolute;
    left: -165px;
}