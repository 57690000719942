.recent .text {
  padding: 30px;
}
.recent .category span {
  padding: 4px 15px;
  border-radius: 2px;
  font-weight: 600;
  font-size: 13px;
  display: inline-block;
}
.recent .category i {
  font-size: 20px;
  color: #bec7d8;
}
.recent p {
  color: #72809d;
}
.recent .button {
  border-top: 1px solid #e4e8f3;
  padding: 10px 30px;
}
.st_blog_main{
  display: flex !important;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
  background-color: white;
  -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  padding: 20px;
  border-radius: 10px;

}
.inner_blog{
  width: 350px;
  transition: transform .2s; /* Animation */


}
.inner_img_blog:hover{
  transform: scale(0.9);
}
.inner_img_blog{
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  transition: transform .2s; /* Animation */

}
.text_blog{
  margin-top: -35px;
}
.height_blog{
  height: 230px;
}
.gold_plate{
  background: -webkit-linear-gradient(top, #DF9F28, #FDE08D, #8f6B29);
	background: linear-gradient(top, #DF9F28, #FDE08D, #8f6B29)
}