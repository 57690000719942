.consulting_main1 {
    display: flex;
    justify-content: center;
    padding: 50px;
}

.consult_child1 {
    border: 1px solid black !important;
    padding: 30px;
    border-radius: 10px;
    width: 500px;
    display: flex;
    justify-content: center !important;
    flex-direction: column;

}

.input_consult2 {
    width: 80%;
    height: 45px;
    padding-left: 10px;
    font-size: 15px;
    margin: 15px 0px;
    border: #cbd6f2;
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    box-shadow: 2px 4px 10px 1px rgb(211, 177, 82);
    border-radius: 5px;
}