.image_corousel {
    height: 100vh;
    width: 100%;
    /* opacity: 0.8; */
    object-fit: cover;

}

.custom_css {
    position: absolute;
    top: 22%;
    left: 30%;
    height: 300px;
    z-index: 2000;
}

.main_corousel {
    overflow-x: hidden;
    overflow-y: hidden;
    /* filter: sepia(100%); */
}

.custom_css h1 {
    font-weight: 500;
}

.consulting_main {
    width: 100%;
    height: auto;
    overflow-x: hidden;
    position: absolute;
    z-index: 2000;
    bottom: -15%;
    display: flex;
    justify-content: center;


}

@media (max-width:500px) {
    .consulting_main {
        width: 100%;
        height: auto;
        overflow-x: hidden;
        position: absolute;
        z-index: 2000;
        bottom: -15%;
        display: flex;
        margin-top: 40;
        justify-content: center;


    }
}

.consult_child {
    padding: 11px;
    background-color: white;
    width: 80vw;
    height: auto;
    border-radius: 10px;
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    box-shadow: 2px 4px 15px 1px rgba(201, 201, 201, 0.47);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 30px;
    margin-bottom: 30px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.font_st_free {
    font-size: 22px;
    color: rgb(211, 177, 82);
}

.input_consult {
    width: 200px;
    height: 45px;
    padding-left: 10px;
    font-size: 15px;
    margin: 15px 0px;
    border: #cbd6f2;
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    box-shadow: 2px 4px 10px 1px rgb(211, 177, 82);
    border-radius: 5px;
}

@media (max-width:500px) {
    .input_consult {
        width: 170px;
        height: 35px;

    }

}

.submit__consult {
    background-color: rgb(211, 177, 82);
    color: white;
}