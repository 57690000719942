.main_blog_comtainer1 {
    padding: 30px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    background-color: white;
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
    margin: 30px 30px 20px 30px;
    border-radius: 10px;
}
.img_selection_blog1{
    width: 70%;
    height: 500px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: white;
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
}
.img_parent{
    display: flex;
    justify-content: center;
    align-items: center;
}