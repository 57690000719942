.footerContact {
  background-color: rgb(211, 177, 82);
  padding: 40px 0;
  color: #fff;
}
.footerContact h1 {
  font-size: 40px;
}
.btn5{
  color: white;
  background-color: black;
  font-size: 17px;
}
@media (max-width:500px) {
.btn5{
  color: white;
  background-color: black;
  font-size: 13px;
  margin-right: 100px !important;

}
}
footer {
  background-color: black;
  padding: 50px 0;
  color: #fff;
}
footer .container {
  display: grid;
  grid-template-columns: 6fr 2fr 2fr 1fr;
  grid-gap: 20px;
}
footer img {
  width: 150px;
}
footer h2 {
  font-weight: 500;
}
footer p {
  color: grey;
  margin: 20px 0;
}
footer input {
  background-color: #fff;
  padding: 17px;
  width: 100%;
  border-radius: 5px;
}
footer h3 {
  font-weight: 500;
  margin-bottom: 30px;
}
footer ul {
  display: block;
}
footer ul li {
  display: block;
  color: grey;
  margin-bottom: 20px;
}
.legal {
  text-align: center;
  padding: 20px;
  background: rgb(38, 37, 37);
  color: white;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}
@media screen and (max-width: 800px) {
  footer .container {
    grid-template-columns: repeat(2, 1fr);
  }
}
.hoverz_st li:hover{
color: rgb(211, 177, 82);
}
.text_footer{
  font-size: 25px !important;
}