.about {
  margin-bottom: 80px;
}

.about .heading {
  text-align: left;
  width: 100%;
  margin-bottom: 30px;
}

.btn2 {
  background-color: rgb(211, 177, 82);
  font-size: 17px;
}

.about p {
  font-size: 15px;
  padding-right: 30px;
  margin-bottom: 20px;
  line-height: 30px;
}

@media screen and (max-width: 768px) {
  .about .container {
    flex-direction: column;
  }

  .about p {
    padding: 0;
  }

  .about .container img {
    margin-top: 50px;
  }
}

.img------st {
  width: 400px !important;
}