.Appsec {
    z-index: 90000000000 !important;
    position: relative;
}

@media (max-width:500px) {
    .Appsec {
        display: none;
    }

}