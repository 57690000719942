.flexbox3 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.input_consult24 {
    width: 80%;
    height: 100px;
    padding-left: 10px;
    font-size: 15px;
    margin: 15px 0px;
    border: #cbd6f2;
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    box-shadow: 2px 4px 10px 1px rgb(211, 177, 82);
    border-radius: 5px;
}

.stfd {
    position: relative;
}

.ptag {
    position: absolute;
    display: flex;
    justify-content: center;
    top: 150px;
    left: 50px;
    color: rgb(255, 246, 246);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    background-color: rgba(0, 25, 37, 0.58);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    width: 30vw;
    font-size: 31PX;
    font-weight: bold;
    padding: 15px;
    border-radius: 15PX;

}

.contextapi {
    position: absolute;
    display: flex;
    justify-content: center;
    top: 70px;
    right: 50px;
    color: rgb(255, 246, 246);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    background-color: rgba(0, 25, 37, 0.58);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    width: 400px;
    font-size: 31PX;
    font-weight: bold;
    padding: 15px;
    border-radius: 15PX;
    height: auto;
}

@media (max-width:500px) {
    .contextapi {

        top: 520px;
        left: 20px;

    }

    .ptag {
        top: 30px;
    }
}