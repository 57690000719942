.margin_top {
    margin-top: 50px;
}

.animate_btn_car {
    position: absolute;
    top: 40%;
    left: 42%;
    background-color: rgb(211, 177, 82);
    font-size: 16px;
    /* width: ; */
    animation: bounce 0.75s infinite;


}

@media (max-width:720px) {
    .animate_btn_car {
        position: absolute;
        top: 35%;
        left: 30%;
        background-color: rgb(211, 177, 82);
        font-size: 13px;
        width: 150px;
        height: 50px;
        align-items: center;
        display: flex;
        justify-content: center;

    }

}

@media (max-width:720px) {
    .slide_car_st {
        margin-top: 60px;
        overflow-x: hidden;
        margin-bottom: 130px !important;

    }

}

@keyframes bounce {
    0% {
        transform: scale(1, 1) translate(0px, 0px);
    }

    30% {
        transform: scale(1, 0.8) translate(0px, 10px);
    }

    75% {
        transform: scale(1, 1.1) translate(0px, -25px);
    }

    100% {
        transform: scale(1, 1) translate(0px, 0px);
    }
}