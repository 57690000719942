  .input_consult5 {
      width: 150px;
      height: 40px;
      padding-left: 10px;
      font-size: 15px;
      margin: 15px 0px;
      border: #cbd6f2;
      -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
      box-shadow: 2px 4px 10px 1px rgb(211, 177, 82);
      border-radius: 5px;
  }

  .consulting_main5 {
      display: flex;
      justify-content: center;
      padding: 20px;
  }

  .consult_child3 {
      border: 1px solid black !important;
      padding: 10px;
      border-radius: 10px;
      width: 100%;
      display: flex;
      justify-content: center !important;
      flex-direction: column;

  }

  .dflex5 {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
  }

  .hero2 {
      width: 100%;
      height: 100vh;
      background-image: url(../images/backcar.jpg);
      background-size: cover;
      background-position: center;
      position: relative;
      overflow: hidden;
  }

  .logo {
      cursor: pointer;
      color: black !important;
      outline: #000729 !important;
      border-color: #000729 !important;
      /* animation: bounce 0.75s infinite; */

  }

  .button8 {
      cursor: pointer;
      color: black !important;
      outline: #000729 !important;
      border-color: #000729 !important;
      animation: bounce 1.5s infinite;

  }

  @keyframes bounce {
      0% {
          transform: scale(1, 1) translate(0px, 0px);
      }

      30% {
          transform: scale(1, 0.8) translate(0px, 10px);
      }

      75% {
          transform: scale(1, 1.1) translate(0px, -25px);
      }

      100% {
          transform: scale(1, 1) translate(0px, 0px);
      }
  }

  navbar {
      width: 85%;
      height: 15%;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
  }

  .logooo {
      width: 350px;
      margin-top: 0px;
  }

  button {
      color: #fff;
      padding: 10px 25px;
      background: transparent;
      border: 1px solid #fff;
      border-radius: 20px;
      outline: pointer;
  }

  .content1 {
      color: #fbfcfd;
      position: absolute;
      top: 50%;
      left: 8%;
      transform: translateY(-50%);
      z-index: 2;
  }

  h1 {
      font-size: 40px;
      margin: 10px 0 30px;
      line-height: 80px;
  }

  .side-bar {
      width: 50px;
      height: 100vh;
      background: linear-gradient(#00545d, #000729);
      position: absolute;
      right: 0;
      top: 0;
  }

  .menu {
      display: block;
      width: 25px;
      margin: 40px auto 0;
      cursor: pointer;
  }

  .social-links img,
  .useful-links img {
      width: 25px;
      margin: 5px auto;
      cursor: pointer;
  }

  .social-links {
      width: 50px;
      text-align: center;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
  }

  .useful-links {
      width: 50px;
      text-align: center;
      position: absolute;
      bottom: 30px;
      transform: translateY(-50%);
  }

  .bubbles img {
      width: 50px;
      animation: bubble 7s linear infinite;
      opacity: 0;
  }

  .bubbles {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-around;
      position: absolute;
      bottom: 0;
  }

  @keyframes bubble (0% {
          transform: translateY(0);
          opacity: 0;
      }

      50% {
          opacity: 1;
      }

      75% {
          opacity: 1;
      }

      100% {
          transform: translateY(-80vh);
          opacity: 0;
      }

  ) .bubbles img:nth-child(1) {
      animation-delay: 2s;
  }

  .bubbles img:nth-child(2) {
      animation-delay: 1s;
  }

  .bubbles img:nth-child(3) {
      animation-delay: 4s;
  }

  .bubbles img:nth-child(4) {
      animation-delay: 5s;
  }

  .bubbles img:nth-child(5) {
      animation-delay: 6s;
  }

  .bubbles img:nth-child(6) {
      animation-delay: 7s;
  }

  .bubbles img:nth-child(7) {
      animation-delay: 8s;
  }

  .happyb {
      overflow: hidden !important;
      margin-top: 50px;
      z-index: 90000000;
  }