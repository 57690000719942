.price {
  text-align: center;
}

.price .content .box {
  width: 31.5%;
  padding: 30px;
  border-radius: 10px;
}

.price button {
  margin-bottom: 20px;
}

.price h3 {
  font-size: 22px;
}

.price h1 {
  font-size: 60px;
}

.price h1 span {
  font-size: 30px;
  font-weight: 500;
}

.price ul {
  margin-top: 40px;
}

.price ul li {
  display: flex;
  margin-bottom: 20px;
}
/* 
.setheight_st {
  height: 100vh;

} */

.content {
  display: flex;
  gap: 20px;
}

.price label {
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 50%;
  margin-right: 20px;
}


.btn6_st {
  width: 100%;

  background-color: rgb(211, 177, 82) !important;
  border: rgb(211, 177, 82);
  color: white;
}

.btn6_st:hover {
  width: 100%;
  background-color: black !important;
  border: black;
  color: white;

}

@media screen and (max-width: 800px) {
  .price .content {
    flex-direction: column;
  }

  .price .content .box {
    width: 100%;
    padding: 30px;
    border-radius: 10px;
    margin-bottom: 50px;
  }
}


/* Modal */

.modal {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Sit on top */
  padding-top: 100px;
  /* Location of the box */
  left: 0;
  top: 0;
  width: 100%;
  margin-top: 100px;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
}

/* Modal Content */
