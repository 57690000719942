.consulting_main2 {
    width: 100%;
    height: auto;
    overflow-x: hidden;
    z-index: 2000;
    display: flex;
    justify-content: center;
}

.consult_child2 {
    padding: 20px;
    background-color: white;
    width: 80vw;
    height: auto;
    border-radius: 10px;
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    box-shadow: 2px 4px 10px 1px rgb(211, 177, 82);
    flex-wrap: wrap;
    gap: 30px;
margin-bottom: 50px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.font_st_free2 {
    font-size: 28px;
    color: rgb(211, 177, 82);
}


.submit__consult {
    background-color: rgb(211, 177, 82);
    color: white;
}
.st_st_st{
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    flex-wrap: wrap;
}