header {
  background-color: black;
  height: 15vh;
  box-shadow: 0 5px 30px rgb(0 22 84 / 10%);
  padding: 15px 0;
  position: sticky;
  top: 0;
  z-index: 99999;
}

.nav {
  margin-bottom: 20px;
}

.logoimgcss {
  margin-top: -25px;
  width: 210px;
  height: 130px;
}

@media (max-width:500px) {
  .logoimgcss {
    margin-top: -25px;
    width: 170px;
    height: 100px;
  }
}

header a {
  margin-left: 30px;
  transition: 0.5s;
  font-weight: 500;
  font-size: 16px;
  color: #fff;
}

.btn_consultnav {
  color: black;
  background-color: rgb(211, 177, 82);
  width: 100%;
  margin-left: 30px;
}

header a:hover {
  color: rgb(211, 177, 82)
}

.btn_toggel {
  background-color: rgb(211, 177, 82) !important;
}

header span {
  width: auto;
  padding: 0px 6px;
  background: rgb(211, 177, 82);
  border-radius: 50px;
  color: #ffffff;
  margin-right: 4px;
  font-weight: 400;
}

header h4 {
  font-weight: 500;
  margin-right: 20px;
}

header .toggle {
  display: none;
}

@media screen and (max-width: 768px) {
  .logoimgcss {
    margin-top: -10px;
  }

  header {
    padding: 0;
  }

  header img {
    margin-top: 30px;
  }

  header .button {
    display: none;
  }

  header .nav .flex {
    display: none;
  }

  header .toggle {
    display: block;
    margin-top: 20px;
  }

  header .small {
    position: absolute;
    top: 15vh;
    left: 0;
    width: 100%;
    background-color: white;
    padding: 20px;
  }

  header li a {
    display: inline-block;
    margin-bottom: 20px;
    color: black;
  }
}





.nav>.nav-header {
  display: inline;
}

.nav>.nav-header>.nav-title {
  display: inline-block;
  font-size: 22px;
  color: #fff;
  padding: 10px 10px 10px 10px;
}

.nav>.nav-btn {
  display: none;
}

.nav>.nav-links {
  display: inline;
  float: right;
  font-size: 18px;

}

.nav>.nav-links>a {
  display: inline-block;
  padding: 13px 10px 13px 10px;
  text-decoration: none;
  color: #efefef;
}

.nav>.nav-links>a:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

.nav>#nav-check {
  display: none;
}



.anchor {
  font-size: 17px;
  margin: 40px 0px 0px 0px !important;

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.anchor2 {
  font-size: 17px;
  margin: 40px 0px 0px 0px !important;

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

@media (max-width:500px) {
  .anchor {
    font-size: 17px;
    margin: 0px 0px 0px 0px !important;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }

  .anchor2 {
    margin: 40px 0px 0px 0px !important;

  }
}

.anchor:hover {
  color: rgb(211, 177, 82) !important;
}

.anchor2:hover {
  color: rgb(211, 177, 82) !important;
}





.topnav {
  overflow: hidden;
  background-color: black;
  z-index: 50000000;

}

.topnav a {
  float: left;
  display: block;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}



.topnav a.active {
  background-color: #04AA6D;
  color: white;
}

.topnav .icon {
  display: none;
}

@media screen and (max-width: 600px) {
  .topnav a:not(:first-child) {
    display: none;
  }

  .topnav a.icon {
    float: right;
    display: block;
  }
}

@media screen and (max-width: 600px) {
  .topnav.responsivee {
    position: relative;
  }

  .topnav.responsivee .icon {
    position: absolute;
    right: 0;
    top: 0;
  }

  .topnav.responsivee a {
    float: none;
    display: block;
    text-align: left;
  }
}