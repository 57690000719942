.main_container_terms{
    padding: 50px 10rem 50px 10rem;
}
@media (max-width:720px) {
    .main_container_terms{
        padding: 50px 0px 50px 0px !important;
    }
    
}
.main_container_terms p{
    font-size: 17px;
}